import './App.css';
import { withRouter } from "react-router-dom";
import React from 'react';

class App extends React.Component {

  render() {
    let currentComponent = this.props.children;
    return(
      <div>
        {currentComponent}
      </div>
    )
  }
}

export default withRouter(App);
