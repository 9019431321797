import React from "react";
import { sendEmail } from "./Api";

class VerifyPayment extends React.Component {

    retrieveOrderIdFromQueryParams() {
        let searchParams = this.props.location.search;
        const orderId = new URLSearchParams(searchParams).get('orderId');
        return orderId;
    }

    render() {
        try {
            let orderId = this.retrieveOrderIdFromQueryParams();
            sendEmail(orderId).then(_ => {
                window.location.replace('https://www.308.dk/betaling-gennemfort/');
            });
        } catch (err) {
            console.log("Email operation failed!")
            window.location.replace('https://www.308.dk/betaling-gennemfort/');
        }

        return (
            <div>
            </div>
        )
    }
}

export default VerifyPayment;