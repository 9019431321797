import axios from "axios";

//const API_URL = "http://localhost:3001"
const API_URL = "https://pacific-falls-48298.herokuapp.com"

export async function getOrder(orderId) {    
    try {
        let response = await axios.get(`${API_URL}/api/get_order?orderId=${orderId}`);
        let payload = response.data;
        return payload;
    } catch (error) {
        return null;
    }
}

export async function sendEmail(orderId) {
    try {
        let response = await axios.post(`${API_URL}/api/send_email?orderId=${orderId}`);
        let payload = response.data;
        return payload;
    } catch (error) {
        return null;
    }
}