import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Payment from './Payment';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import VerifyPayment from './VerifyPayment';

ReactDOM.render(
  <BrowserRouter>
    <App>
      <div>
        <Switch>
          <Route path="/verify-payment" component={VerifyPayment} />
          <Route path="/" component={Payment} />
        </Switch>
      </div>
    </App>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
