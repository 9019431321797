import React from 'react';
import axios from "axios";
import { getOrder } from "./Api";
import Loader from "react-loader-spinner";
var CheckoutSDK = require("@bambora/checkout-sdk-web");

class Payment extends React.Component {

    retrieveOrderIdFromQueryParams() {
        let searchParams = this.props.location.search;
        const orderId = new URLSearchParams(searchParams).get('orderId');
        return orderId;
    }

    async getSessionToken(orderId, amount) {
        let config = {
            auth: {
                username: `${process.env.REACT_APP_BAMBORA_USERNAME}`,
                password: `${process.env.REACT_APP_BAMBORA_PASSWORD}`
            }
        }

        let sessionObject = {
            "order": {
                "id": orderId,
                "amount": parseInt(amount),
                "currency": "DKK"
            },
            "url": {
                "accept": `https://betaling.308.dk/verify-payment?orderId=${orderId}`,
                "cancel": "https://www.308.dk/betaling-ikke-gennemfort/"
            },
            "paymentwindow": {
                "language": "da-DK"
            },
            "securitylevel": "require3d"
        }

        try {
            let response = await axios.post("https://api.v1.checkout.bambora.com/sessions", sessionObject, config);
            let payload = response.data;
            return payload.token;
        } catch (error) {
            return null;
        }
    }

    formatAmount(amount) {
        if (!String.prototype.includes) {
            String.prototype.includes = function (search, start) {
                'use strict';
                if (typeof start !== 'number') {
                    start = 0;
                }

                if (start + search.length > this.length) {
                    return false;
                } else {
                    return this.indexOf(search, start) !== -1;
                }
            };
        }

        let stringAmount = `${amount}`;
        if (stringAmount.includes('.')) {
            return stringAmount.replace('.', '') + "0";
        } else {
            return stringAmount + "00";
        }
    }

    render() {
        let orderId = this.retrieveOrderIdFromQueryParams();
        if (orderId == null) {
            console.log("Missing order id");
        }

        getOrder(orderId).then(shootProofOrder => {
            let grandTotal = shootProofOrder.grandTotal
            this.getSessionToken(orderId, this.formatAmount(grandTotal)).then(sessionToken => {
                new CheckoutSDK.RedirectCheckout(sessionToken);
            })
        });

        return (
            <div className="App">
                <Loader
                    type="ThreeDots"
                    color="#f29f05"
                    height={200}
                    width={200}
                    timeout={10000}
                />
            </div>
        );
    }
}

export default Payment;